<template>
  <div id="vehicleDetail">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设备管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'vehicleList' }"
        >车辆列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicsInfo">
      <h3>基础信息</h3>
      <ul class="info">
        <li>
          <span class="label">车牌号：</span>
          <editable-input :value="info.plateNum" />
        </li>
        <li>
          <span class="label">车辆类型：</span>
          <editable-input :value="info.vehicleType" />
        </li>
        <li>
          <span class="label">车辆颜色：</span>
          <editable-input :value="info.vehicleColor" />
        </li>
        <li>
          <span class="label">车辆品牌：</span>
          <editable-input :value="info.vehicleBrand" />
        </li>
        <li>
          <span class="label">车辆大小：</span>
          <editable-input :value="info.vehicleSize" />
        </li>
        <li>
          <span class="label">绑定人数：</span>
          <editable-input :value="info.bindNum" />
        </li>
      </ul>
    </div>
    <div class="tab-table">
      <el-tabs v-model="tabsName">
        <el-tab-pane
          label="出入场记录"
          name="vehicleList-detail-passagewayTab"
          v-if="tabList.includes('vehicleList-detail-passagewayTab')"
        >
          <passageway-records />
        </el-tab-pane>
        <el-tab-pane
          label="绑定人"
          name="vehicleList-detail-bindingPeopleTab"
          v-if="tabList.includes('vehicleList-detail-bindingPeopleTab')"
        >
          <binding-people />
        </el-tab-pane>
        <el-tab-pane
          label="套餐列表"
          name="vehicleList-detail-setmealTab"
          v-if="tabList.includes('vehicleList-detail-setmealTab')"
        >
          <setmeal-list :info="info" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    editableInput: () => import("@/components/editable-input.vue"),
    passagewayRecords: () => import("./tabs/passagewayRecords.vue"),
    bindingPeople: () => import("./tabs/bindingPeople.vue"),
    setmealList: () => import("./tabs/setmealList.vue"),
  },
  data() {
    return {
      tabsName: "vehicleList-detail-passagewayTab",
      info: {},
    };
  },
  created() {
    this.getInfo();
  },
  computed: {
    tabList() {
      let list = this.$store.state.controlAuthority.tabList;
      let tabsName_list = [
        "vehicleList-detail-passagewayTab",
        "vehicleList-detail-bindingPeopleTab",
        "vehicleList-detail-setmealTab",
      ];
      for (let item of tabsName_list) {
        if (list.includes(item)) {
          this.tabsName = item;
          break;
        }
      }
      return list;
    },
  },
  methods: {
    async getInfo() {
      try {
        let res = await this.$http.get(
          `/vehicle/detail/${this.$route.params.id}`
        );
        if (res.code === 0) {
          this.$set(this, "info", res.data);
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
  },
};
</script>

<style lang="scss">
#vehicleDetail {
  .tab-table {
    padding: 15px;
  }
}
</style>
